<template>
  <div class="constrain part-intern">
    <div class="row-12">
      <div class="md:col-9 md:offset-3">
        <div v-for="element in news" :key="element.id" class="pb-12 mb-12 border-b-2 border-lightblue">
          <h3 class="mb-8" v-html="element.titel" />
          <p class="text-navy mb-8">
            Gesendet am {{ element.datum }}
          </p>
          <div class="flex items-center file-link" v-if="element.datei[0]">
            <File />
            <a class="ml-4 text-navy" :href="element.datei[0].link" target="_blank">Ansehen</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import File from '../../assets/images/file.svg';

export default {
  components: {
    File,
  },
  props: {
    payload: Object,
  },
  computed: {
    news() {
      return this.payload.newsletterelement;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
.file-link {
      svg {
      width: px(30);
      height: px(30);
      @screen md {
        width: px(40);
        height: px(40);
      }
      @screen lg {
        width: px(45);
        height: px(45);
      }
    }
    a {
      background-image: linear-gradient(var(--color-navy), var(--color-navy));
      background-size: 0 px(2);
      background-repeat: no-repeat;
      background-position: bottom left;
      will-change: background-size;
      transition: background-size .25s ease-out;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover a {
        background-size: 100% px(2);
        color: var(--color-navy);
      }
    }
}
</style>
